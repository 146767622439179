import {APP_INITIALIZER, enableProdMode, ErrorHandler, importProvidersFrom} from '@angular/core';
import {environment} from './environments/environment';
import * as Sentry from '@sentry/angular';
import packageJson from '../package.json';
import {bootstrapApplication} from '@angular/platform-browser';
import {AppComponent} from './app/app.component';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import {authInterceptor} from './app/core/interceptors/auth.interceptor';
import {
  internalServerErrorInterceptor,
} from './app/core/interceptors/internal-server-error.interceptor';
import {GlobalErrorHandlerService} from './app/core/global-error-handler.service';
import {provideRouter, Router, withRouterConfig} from '@angular/router';
import {provideAnimations} from '@angular/platform-browser/animations';
import {appRoutes} from './app/app.routes';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatDialogModule} from '@angular/material/dialog';
import {MatNativeDateModule} from '@angular/material/core';
import {contentTypeInterceptor} from './app/core/interceptors/content-type.interceptor';
import {environmentInterceptor} from './app/core/interceptors/environment.interceptor';
import {browserTracingIntegration, init} from '@sentry/angular';


if (environment.production) {
  enableProdMode();
  init({
    dsn: 'https://b94c294f4d744b469e738e6f46b21b71@o4504690212470784.ingest.sentry.io/4504690214436864',
    release: packageJson.version,
    environment: environment.production ? 'prod' : 'dev',
    tracePropagationTargets: ['localhost', 'https://yourserver.io/api'],
    integrations: [
      browserTracingIntegration(),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}

bootstrapApplication(AppComponent, {
  providers: [
    importProvidersFrom([MatDialogModule, MatSnackBarModule, MatNativeDateModule]),
    provideAnimations(),
    provideHttpClient(withInterceptors([authInterceptor, environmentInterceptor, contentTypeInterceptor, internalServerErrorInterceptor])),
    provideRouter(appRoutes,
      withRouterConfig({
        paramsInheritanceStrategy: 'always',
      }),
    ),
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandlerService,
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {
      },
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
});
