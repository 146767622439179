import { HttpInterceptorFn, HttpRequest } from '@angular/common/http';
import {ApplicationsStoreService} from '../applications-store.service';
import {inject} from '@angular/core';

export const environmentInterceptor: HttpInterceptorFn = (req: HttpRequest<any>, next) => {
  const appId: string | number = req.params.get('applicationId') ?? req.body?.applicationId;
  const appEnv = appId ? inject(ApplicationsStoreService).getAppById(+appId)?.environment ?? 'alpha' : 'alpha';
  const cloned = req.clone({
    headers: req.headers.set('Environment', appEnv),
  });

  return next(cloned);
};
